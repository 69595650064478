@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
    font-family: 'CircularStd-Book';
    src: local('CircularStd-Book'), url(fonts/CircularStd-Book.ttf) format('truetype');
}

@font-face {
    font-family: 'CircularStd-Medium';
    src: local('CircularStd-Medium'), url(fonts/CircularStd-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'CircularStd-Bold';
    src: local('CircularStd-Bold'), url(fonts/CircularStd-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'CircularStd-Black';
    src: local('CircularStd-Black'), url(fonts/CircularStd-Black.ttf) format('truetype');
}

body {
    font-family: 'CircularStd-Book' !important;
    background-color: #101010;
    color: #ffffff;
}

@media (min-width: 1200px) {
    .container-padding {
        padding-left: 150px;
        padding-right: 150px;
    }
}

.button-primary {
    background-color: #101010 !important;
    border: 2px solid #ffffff !important;
    padding: 10px 20px;
    padding-top: 11px;
    font-size: 16px;
    text-transform: uppercase;
}

@media (min-width: 992px) {
.button-primary {
    background-color: #101010 !important;
    border: 2px solid #ffffff !important;
    padding: 10px 16px;
    padding-top: 11px;
    font-size: 12px;
    text-transform: uppercase;
}
}

.iframe-container {
    width: 1200px;
    height: 450px;
    overflow: hidden;
    position: relative;
  }
  
  .iframe-container iframe {
    position: absolute;
  width: 940px;
  height: 580px;
  left: -40px;
  top: -45px;
  border: 0;
    bottom: -50px;
  }

.button-primary:hover {
    background-color: #202020 !important;
}

.button-secondary {
    background-color: #748BF5 !important;
    border: 2px solid #748BF5 !important;
    padding: 10px 16px;
    padding-top: 11px;
    font-size: 12px;
    text-transform: uppercase;
}

.button-secondary:hover {
    background-color: #6a82f3 !important;
}

.button-secondary:focus,
.button-primary:focus {
    box-shadow: none !important;
}

.button-alt {
    background-color: #fff !important;
    border: 2px solid #fff !important;
    padding: 10px 16px;
    padding-top: 11px;
    font-size: 12px;
    text-transform: uppercase;
    color: black;
}

.button-alt:hover {
    background-color: rgb(225, 225, 225) !important;
    border: 2px solid rgb(225, 225, 225) !important;
    color: black;
}

.social-button {
    background-color: #101010 !important;
    width: 35px;
    height: 35px;
    padding: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.5px solid #FFFFFF !important;
    box-shadow: none !important;
}

.social-button:hover {
    background-color: #202020 !important;
}

.select-primary {
    position: relative;
}

.input-primary input,
.select-primary select {
    background: #202020 !important;
    border: 1px solid rgba(255, 255, 255, 0.4) !important;
    color: #888888 !important;
    border-radius: 12px;
    height: 56px;
    transition: all .15s ease-in-out;
}

.input-primary input:focus,
.select-primary select:focus {
    border: 1px solid #CFD0D7 !important;
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5);
    background: #141414 !important;
}

.select-primary svg {
    position: absolute;
    right: 15px;
    bottom: 20px;
    opacity: 0.5;
}

.checkbox-primary .form-check-input:checked {
    background-color: #748BF5;
    border-color: #748BF5;
}

.checkbox-primary .form-check-input:focus {
    box-shadow: 0 0 0 0.15rem rgb(116 139 245 / 25%);
}

.heading {
    font-family: 'CircularStd-Bold';
    font-size: 38px;
    line-height: 48px;
    letter-spacing: 0.02em;
}

.modal-content {
    background: #101010;
    border-radius: 24px;
    padding-top: 64px;
    width: 640px;
}

.modal-content img {
    max-width: 175px;
}

.custom_dropdown .dropdown-menu {
    background: #202020;
    border: 1px solid rgba(255, 255, 255, 0.4);
    max-height: 360px;
    overflow: auto;
}

.custom_dropdown .dropdown-menu a {
    background: #202020;
    color: #C9C8CC;
    font-size: 14px;
    padding: 3px 10px;
}

.custom_dropdown .dropdown-menu a:hover {
    background: #3b3b3b;
}