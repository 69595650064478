.candy-card-info {
    color: white !important;
    background-color: #202020 !important;
}

.candy-card-info .name {
    padding-top: 10px !important;
    padding-left: 5px !important;
}

.candy-card .candy-card-footer {
    padding-bottom: 10px !important;
    padding-left: 5px !important;
}

.candy-card-border {
    border: 1px solid #2A2A2A !important;
    border-radius: 16px !important;
}

.candy-status-tag-gray {
    background: rgba(255,255,255,0.699999988079071) !important;
}

.candy-status-tag {
    position: absolute !important;
    left: -12px !important;
    top: 18px !important;
    padding: 7px 12px !important;
    line-height: 18px !important;
    text-transform: uppercase !important;
    color: #000 !important;
    font-weight: bold !important;
    font-size: 12px !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    z-index: 1 !important;
}

.candy-powered-by-buy {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: #202020 !important;
    font-weight: 600 !important;
    margin: 0 auto !important;
    width: fit-content !important;
    margin-top: 10px !important;
    text-decoration: none !important;
}

.candy-modal .candy-modal-content {
    color: black !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    padding: 40px !important;
    max-width: 90vw!important;
    overflow-y: auto !important;
    border-radius: 16px !important;
    border: 2px solid #2A2A2A !important;
    background-color: #202020 !important;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%) !important;
}

@media only screen and (max-width: 1440px) {
.candy-modal .candy-modal-content {
    padding: 30px 40px 10px;
}
}

.candy-modal-close-btn .close {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 32px !important;
    height: 32px !important;
    opacity: 0.8 !important;
    transition: 0.2s all ease-in-out !important;
}

.candy-auction-modal .candy-title {
    margin: 20px 0 !important;
    color: #fff !important;
}

.candy-price {
    font-size: 20px !important;
    font-weight: bold !important;
    line-height: 30px !important;
    text-align: left !important;
    color: #fff !important;
}

.candy-value {
    font-size: 16px !important;
    font-weight: 400 !important;
    text-align: left !important;
    word-break: break-word !important;
    color: white !important;
}

.candy-attribute-container .candy-nft-attribute {
    color: white !important;
    padding: 4px 12px !important;
    background-color: #202020 !important;
    border-radius: 8px !important;
    border: 1px solid #797979 !important;
    font-size: 14px !important;
    margin-right: 6px !important;
    margin-bottom: 6px !important;
}

.candy-value a {
    color: white !important;
}

.candy-auction-modal-countdown .candy-countdown {
    background: white !important;
    color: #000 !important;
    width: fit-content !important;
    padding: 8px 14px !important;
    border-radius: 12px !important;
    font-size: 12px !important;
    text-transform: uppercase !important;
    font-weight: bold !important;
}

.candy-auction-modal-countdown .candy-countdown.candy-countdown-ended, .candy-auction-modal-countdown .candy-countdown.candy-countdown-not-started {
    background: #fff !important;
}

.wallet-adapter-button {
    background-color: transparent !important;
    border: none !important;
    color: #fff !important;
    cursor: pointer !important;
    display: flex !important;
    align-items: center !important;
    font-family: 'DM Sans', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    height: 48px !important;
    line-height: 48px !important;
    padding: 0 24px !important;
    border-radius: 4px !important;
}

.candy-card-info .ticker {
    font-size: 12px !important;
    text-align: left !important;
    font-weight: 600 !important;
    color: #757575 !important;
    text-transform: uppercase !important;
    min-height: 1.57em !important;
    padding-left: 5px !important;
}

.footer-link {
    color: white !important;
    text-decoration: none !important;
}

a:hover {
    color: #748BF5 !important;
  }

  .wallet-adapter-dropdown {
    position: relative !important;
    display: inline-block !important;
}

.candy-auction-modal .candy-auction-modal-container .candy-auction-modal-control .candy-input-label {
    font-size: 14px !important;
    margin-bottom: 5px !important;
    color: white !important;
}

.candy-input-price input {
    border: none !important;
    outline: none !important;
    flex-grow: 1 !important;
    background-color: #202020 !important;
}
