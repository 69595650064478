#root {
    .navbar {
        background-color: #101010;
        height: 90px;

        .navbar-brand {
            img {
                max-width: 157px;
            }
        }

        // .nav-link {
        //     text-transform: uppercase;
        //     font-size: 16px;
        //     margin-right: 20px;
        //     font-family: "CircularStd-Medium";

        //     a {
        //         color: #7D7D7D;
        //         text-decoration: none;
        //     }
        // }

        @media (min-width: 992px) {
        .nav-link {
            text-transform: uppercase;
            font-size: 13px;
            margin-right: 16px;
            font-family: "CircularStd-Medium";

            a {
                color: #7D7D7D;
                text-decoration: none;
            }
        }
    }

        .user-avatar {
            width: 54px;
            height: 54px;
            border-radius: 50%;
            cursor: pointer;
        }

        @media (min-width: 992px) {
        .user-avatar {
            width: 44px;
            height: 44px;
            border-radius: 50%;
            cursor: pointer;
        }
    }
}

}