#root {
    .time_picker {
        .dropdown {
            button {
                position: relative;
                color: #888888 !important;
                background: #202020;
                border: 1px solid rgba(255, 255, 255, 0.4);
                border-radius: 12px;
                height: 56px;
                width: 65px;
                padding: 0.375rem 2.25rem 0.375rem 0.75rem;

                &:focus {
                    border: 1px solid #CFD0D7;
                    box-shadow: 0px 0px 10px rgb(255 255 255 / 50%);
                    background: #141414 !important;
                }

                &::after {
                    display: none;
                }

                svg {
                    position: absolute;
                    right: 15px;
                    bottom: 20px;
                }
            }
        }

        .colon-icon {
            width: 3px;
        }
    }
}