#root {
  .explore-single-item {
    .item-image {
      position: relative;

      img {
        border: 3px solid #101010;
        filter: drop-shadow(0px 8px 28px rgba(0, 0, 0, 0.16));
        border-radius: 14px;
        width: 100%;
      }

      .image-filter {
        width: 80%;
        height: 90%;
        position: absolute;
        left: 10%;
        top: 10%;
        opacity: 0.8;
        filter: blur(20px);
        border-radius: 32px;
      }
    }

    .info-table {
      background: linear-gradient(
        103.88deg,
        rgba(181, 181, 181, 0.08) 28.49%,
        rgba(181, 181, 181, 0) 103.07%
      );
      border: 1px solid #232323;
      box-shadow: inset 1.39157px -1.39157px 2.78313px rgba(31, 31, 31, 0.2),
        inset 0px 1.5px 1px #414141,
        inset -1.39157px -1.39157px 4.1747px rgba(31, 31, 31, 0.9);
      filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.16));
      border-radius: 8px;

      .table-item {
        border-bottom: 1px solid #232323;
        height: 54px;

        .type {
          width: 30%;
          border-right: 1px solid #232323;
          padding: 20px;
          font-size: 12px;
          letter-spacing: 0.04em;
          color: #a1a1a1;
          font-family: "CircularStd-Medium";
          text-transform: uppercase;
        }

        .value {
          width: 70%;
          padding: 20px;
          font-size: 14px;
          letter-spacing: 0.04em;
          text-align: center;
        }

        &:nth-last-child(1) {
          border-bottom: none;
        }
      }
    }

    .watch-btn {
      cursor: pointer;
      background: #202020;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16),
        inset 1.39157px -1.39157px 2.78313px rgba(31, 31, 31, 0.2),
        inset 0px 1.5px 1px #414141,
        inset -1.39157px -1.39157px 4.1747px rgba(31, 31, 31, 0.9);
      border-radius: 6px;
      padding: 20px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      text-decoration: none;
      color: white;

      h6 {
        text-transform: uppercase;
        font-family: "CircularStd-Bold";
        font-size: 16px;
        margin-bottom: 0;
        margin-right: 68px;
      }

      .watch-icon {
        position: absolute;
        right: 0;
        height: 100%;
        width: 68px;
        border-left: 1px solid #111111;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .description {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: 0.04em;
      opacity: 0.8;
    }

    .created-by {
      h6 {
        font-family: "CircularStd-Medium";
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.04em;
        color: #a1a1a1;
      }

      .avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }

      .owner-name {
        font-size: 14px;
        line-height: 18px;
        opacity: 0.6;
      }
    }

    .small-title {
      font-family: "CircularStd-Medium";
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.04em;
      color: #a1a1a1;
      text-transform: uppercase;
    }

    .info-card {
      background: #202020;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16),
        inset 1.39157px -1.39157px 2.78313px rgba(31, 31, 31, 0.2),
        inset 0px 1.5px 1px #414141,
        inset -1.39157px -1.39157px 4.1747px rgba(31, 31, 31, 0.9);
      border-radius: 8px;
      padding: 24px;

      .time-text {
        font-family: "Space Mono";
        font-style: normal;
        font-weight: 700;
        font-size: 38px;
        line-height: 56px;
        text-align: center;
        letter-spacing: 0.06em;
        color: #ffffff;

        span {
          font-size: 25px;
          color: #a1a1a1;
        }
      }

      .wallet-button,
      .bid-button,
      .bid-placed-button {
        border-radius: 6px;
        height: 61px;
        width: 100%;
        border: none !important;
        box-shadow: none !important;
        text-transform: uppercase;
      }

      .wallet-button {
        background: linear-gradient(251.3deg, #ef4957 24%, #ff97a0 97.51%);
        font-weight: bold;
      }

      .bid-button {
        background: #748bf5;
      }

      .bid-placed-button {
        cursor: default;
        background: linear-gradient(
          269.61deg,
          #464646 -34.96%,
          rgb(95 95 95 / 6%) 96.93%
        );
        border: 0.5px solid #545454 !important;
      }
      .react-datetime-picker__wrapper {
        border: 0px solid;
        width: 100%;
      }
      .react-datetime-picker__inputGroup__input {
        border: 0px solid !important;
        width: 50px !important;
        text-align: center !important;
      }
    }

    .mint-message {
      margin-top: 15px;
      margin-bottom: 0px;
      text-align: center;
      font-family: "CircularStd-Book" !important;
    }
  }
}
