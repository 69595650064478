#root {
    .sign-up {
        .input-pair {
            .input-primary {
                width: 45%;
            }
        }

        .signup-form {
            label {
                color: #5F6377;
            }
        }
    }
}