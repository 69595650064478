.player-card {
    .player-avatar {
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin: 0 12px;

        img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
        }

        .image-filter {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0.12;
            filter: blur(4px);
            border-radius: 50%;
        }
    }
}