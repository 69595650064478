#root {
    .back-button {
        height: 54px;
        width: 54px;
        background: #202020;
        border: none;
        border-radius: 50%;
        /* GLARE */
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16), inset 1.39157px -1.39157px 2.78313px rgba(31, 31, 31, 0.2), inset 0px 1.5px 1px #414141, inset -1.39157px -1.39157px 4.1747px rgba(31, 31, 31, 0.9);
    }
}